import React, { useEffect, useState } from 'react';
import type { PaymentPoDetails, PaymentSchedule, PaymentSchedulesItems } from 'api/types';
import { dateFormatter, getAssociatedJobs, getPrice, roundToTwoDecimalPlaces } from 'utils/index';
import PaymentScheduleModal, {
  paymentMethods
} from 'pages/ProcurementWorkspace/components/PaymentScheduleModal';
import EditIcon from 'assets/images/edit.svg';
import BillUpdateModal from './BillUpdateModal';
import { usePaymentWorkspaceStore } from 'store/index';
import type { DataTableObjectType } from 'utils/types';
import DataTable from 'components/NewLayout/Table';
import { SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import Badge from 'components/NewLayout/Badge';
import AddCirle from 'assets/images/add_circle.svg';
import Can from 'components/Can';
import EditableTableCell from 'components/EditableTableCell';
import toast from 'react-hot-toast';
import { updatePaymentSchedule } from 'api/index';
import { priceMinNumber, priceMaxNumber } from 'utils/constants';

const PaymentScheduleTable = React.memo(function PaymentScheduleTable({
  loading,
  onOpenPaymentScheduleModal,
  totalCount,
  paymentSchedule,
  fetchAllPayments
}: {
  loading: boolean;
  onOpenPaymentScheduleModal: (
    purchaseOrderData: PaymentPoDetails,
    paymentSchedules: PaymentSchedulesItems[]
  ) => void;
  fetchAllPayments: () => void;
  paymentSchedule: PaymentSchedule[];
  totalCount: number;
}) {
  const [rowVisible, setRowVisible] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPaymentScheduleModal, setShowPaymentScheduleModal] = useState<boolean>(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<string>('');
  const [selectedBillId, setSelectedBillId] = useState<number>(0);
  const [selectedPoTotalPrice, setSelectedPoTotalPrice] = useState<string>('');
  const [selectedPoOtherBillTotal, setSelectedPoOtherBillTotal] = useState<string>('');
  const [selectedVendorPayment, setSelectedVendorPayment] = useState<string>('');
  const [paymentPoDetails, setPaymentPoDetails] = useState<PaymentPoDetails>();
  const [isProjectManager, setIsProjectManger] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [isBasicUser, setIsBasicUser] = useState<boolean>(false);
  const [apiInProgress, setApiInProgress] = useState<any>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleTr = (row: PaymentSchedule, index: number) => {
    const RV = index == rowVisible ? -1 : index;
    setRowVisible(RV);
    setSelectedPurchaseOrderId(row.purchase_order_id);
    setSelectedPoTotalPrice(row.po_total_price);
    let totalAllBillAmount = 0;
    row.paymentschedules.map((item: PaymentSchedulesItems) => {
      totalAllBillAmount += parseInt(item.amount?.toString());
    });
    setSelectedPoOtherBillTotal(totalAllBillAmount.toString());
    setSelectedVendorPayment(row.default_payment_method);
    setPaymentPoDetails({
      total_amount: parseInt(row.po_total_price),
      paid_amount: getPrice(row, 'total'),
      remaining_amount: getPrice(row, 'remaining'),
      scheduled_amount: getPrice(row),
      po_number: row.purchase_order_number,
      vendor_name: row.vendor_name
    });
  };

  const onClickOpenPaymentScheduleModal = (row: PaymentSchedule) => {
    const purchaseOrderData = {
      total_amount: parseInt(row.po_total_price),
      paid_amount: getPrice(row, 'total'),
      remaining_amount: getPrice(row, 'remaining'),
      scheduled_amount: getPrice(row),
      po_number: row.purchase_order_number,
      vendor_name: row.vendor_name,
      po_id: row.purchase_order_id
    };
    onOpenPaymentScheduleModal(purchaseOrderData, row.paymentschedules);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const userRoles: any = localStorage.getItem('userRoles');
    const userRolesArr: string[] = userRoles?.split(',');
    setRoles(userRolesArr);
    if (userRolesArr.length === 1 && userRolesArr[0] === 'project_manager') {
      setIsProjectManger(true);
    }
    if (userRolesArr.length === 1 && userRolesArr[0] === 'basic_user') {
      setIsBasicUser(true);
    }
  }, []);

  const onClickAddPaymentItems = () => {
    setShowPaymentScheduleModal(true);
  };

  const onClickUpdatePaymentItem = (billId) => {
    setSelectedBillId(billId);
  };

  const onEditingComplete = (dataToUpdate: PaymentSchedulesItems, cellId: string) => {
    setApiInProgress({ ...apiInProgress, [cellId]: false });

    const previousState = usePaymentWorkspaceStore.getState().allPaymentsSchedules;

    dataToUpdate.purchase_order_id = selectedPurchaseOrderId;
    updatePaymentScheduleItem(dataToUpdate);

    updatePaymentSchedule(dataToUpdate, dataToUpdate?.bill_id)
      .then((res: { message: string }) => {
        if (res.message !== 'Payment Schedule updated successfully!') {
          throw new Error(res.message);
        }
      })
      .catch((error) => {
        usePaymentWorkspaceStore.setState({ allPaymentsSchedules: previousState });
        toast.error(
          'Failed to update Purchase Order Request Item: ' + (error.message || 'An error occurred'),
          {
            position: 'bottom-left',
            duration: 3000
          }
        );
      });
  };

  function getClosestScheduled(param) {
    if (!param || param.length === 0) return '';

    const currentDate = new Date();

    const closest = param.reduce((closestItem, currentItem) => {
      const currentDueDate = new Date(currentItem.due_date);
      const closestDueDate = new Date(closestItem.due_date);

      return Math.abs(currentDueDate.getTime() - currentDate.getTime()) <
        Math.abs(closestDueDate.getTime() - currentDate.getTime())
        ? currentItem
        : closestItem;
    });

    return dateFormatter(closest.due_date, 'date');
  }

  const columns: DataTableObjectType<PaymentSchedule>[] = [
    {
      prop: 's3_uri',
      name: '',
      data: (row, index) => {
        const i: number = parseInt(index?.toString()?.split('-')[0] || '-1');
        return (
          <div className="icon-div">
            <SvgIcon
              sx={{ cursor: 'pointer', marginTop: '8px' }}
              component={rowVisible == i ? KeyboardArrowDown : KeyboardArrowRight}
              onClick={() => toggleTr(row, parseInt(i.toString()))}
            />
            <img
              src={EditIcon}
              className={`img-fluid link-icon`}
              style={{ marginLeft: '8px' }}
              alt="edit icon"
              onClick={() => onClickOpenPaymentScheduleModal(row)}
            />
          </div>
        );
      }
    },
    {
      prop: 'purchase_order_number',
      data: 'purchase_order_number',
      name: 'Purchase Order Number',
      isSort: true
    },
    {
      prop: 'po_total_price',
      data: (row) => '$' + roundToTwoDecimalPlaces(row.po_total_price),
      name: 'Total PO Amount',
      isSort: true
    },
    {
      prop: 'total_paid',
      data: (row) => '$' + roundToTwoDecimalPlaces(getPrice(row, 'total')),
      name: 'Total Paid',
      isSort: true
    },
    {
      prop: 'total_remaining',
      data: (row) => '$' + roundToTwoDecimalPlaces(getPrice(row, 'remaining')),
      name: 'Total Remaining',
      isSort: true
    },
    {
      prop: 'total_scheduled',
      data: (row) => '$' + roundToTwoDecimalPlaces(getPrice(row)),
      name: 'Total Scheduled',
      isSort: true
    },
    {
      prop: 'associatedjob',
      data: (row) => getAssociatedJobs(row.associatedjob),
      name: 'Associated Job'
    },
    {
      prop: 'closest_scheduled',
      data: (row) => getClosestScheduled(row.subRows),
      name: 'Closest Scheduled',
      isSort: true
    },
    {
      prop: 'created_by',
      data: 'created_by',
      name: 'Created By',
      isSort: true
    },
    {
      prop: 'vendor_name',
      data: 'vendor_name',
      name: 'Vendor',
      isSort: true
    },
    {
      prop: 'created_at',
      data: 'created_at',
      name: 'Created At',
      type: 'date',
      dateFormat: 'date',
      isSort: true
    }
  ];

  const subColumns: DataTableObjectType<PaymentSchedulesItems>[] = [
    {
      name: '',
      prop: 'edit_icon',
      data: (row) => {
        const isAdmin = roles.includes('admin');
        const isEditable = row.status !== 'Paid';

        const editIcon = isAdmin && (
          <div
            onClick={() => isEditable && onClickUpdatePaymentItem(row.bill_id)}
            style={{
              cursor: isEditable ? 'pointer' : 'not-allowed',
              display: 'inline-block',
              margin: '0 0 6px 12px'
            }}
            className="icon-div"
            aria-disabled={!isEditable}
            title={!isEditable ? 'Editing is not allowed for paid items' : ''}>
            <img
              style={{
                pointerEvents: isEditable ? 'auto' : 'none'
              }}
              src={EditIcon}
              className="img-fluid link-icon"
              alt="Edit icon"
            />
          </div>
        );

        const mobileButton = (
          <Typography
            component="div"
            sx={{
              background: '#335d87',
              padding: '10px 13px 12px 13px',
              display: 'inline',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={onClickAddPaymentItems}
            role="button"
            aria-label="Add Payment Items"
            title="Add Payment Items">
            <img src={AddCirle} alt="Add icon" />
          </Typography>
        );

        return isMobile ? mobileButton : editIcon;
      }
    },
    {
      prop: 'amount',
      data: (row, id) => {
        if (row.quickbooks_id != null) {
          return '$' + row.amount;
        } else {
          const cellId = `${row.bill_id}-${id}`;
          return isProjectManager ||
            roles.includes('admin') ||
            roles.includes('logistics') ||
            roles.length > 1 ? (
            <Can I="update" module="PaymentHub" displayTextOnly={'$' + row.amount?.toString()}>
              <EditableTableCell
                name="itemPrice"
                type="number"
                value={row.amount?.toString() || ''}
                onEditComplete={(val) =>
                  onEditingComplete(
                    {
                      bill_id: row.bill_id,
                      amount: val
                    },
                    cellId
                  )
                }
                apiInProgress={apiInProgress[cellId]}
                setApiInProgress={(val) =>
                  setApiInProgress({
                    [cellId]: val
                  })
                }
                isPrice={true}
              />
            </Can>
          ) : (
            row.amount
          );
        }
      },
      name: 'Amount'
    },
    {
      prop: 'due_date',
      data: (row, id) => {
        if (row.quickbooks_id != null) {
          return dateFormatter(row.due_date, 'date');
        } else {
          const cellId = `${row.bill_id}-${id}`;
          return isProjectManager ||
            roles.includes('admin') ||
            roles.includes('logistics') ||
            roles.length > 1 ? (
            <Can
              I="update"
              module="PaymentHub"
              displayTextOnly={dateFormatter(row.due_date, 'date')}>
              <EditableTableCell
                value={dateFormatter(row.due_date, 'date')}
                onEditComplete={(val) => {
                  if (val.trim()) {
                    onEditingComplete(
                      {
                        bill_id: row.bill_id,
                        due_date: val
                      },
                      cellId
                    );
                  }
                }}
                apiInProgress={apiInProgress[cellId]}
                type="date"
                setApiInProgress={(val) =>
                  setApiInProgress({
                    [cellId]: val
                  })
                }
              />
            </Can>
          ) : (
            dateFormatter(row.due_date, 'date')
          );
        }
      },
      name: 'Due Date'
    },
    {
      prop: 'payment_method',
      data: (row, id) => {
        if (row.quickbooks_id != null) {
          return row.payment_method;
        } else {
          const cellId = `${row.bill_id}-${id}`;
          return isProjectManager ||
            roles.includes('admin') ||
            roles.includes('logistics') ||
            roles.length > 1 ? (
            <Can I="update" module="PaymentHub" displayTextOnly={row.payment_method}>
              <EditableTableCell
                id={row.payment_method}
                onEditComplete={(val) =>
                  onEditingComplete(
                    {
                      bill_id: row.bill_id,
                      id: val,
                      payment_method: paymentMethods.find((v) => v.id == val)?.value
                    },
                    cellId
                  )
                }
                isDropdown
                options={paymentMethods}
                value={row.payment_method}
                apiInProgress={apiInProgress[cellId]}
                setApiInProgress={(val) =>
                  setApiInProgress({
                    [cellId]: val
                  })
                }
              />
            </Can>
          ) : (
            row.payment_method
          );
        }
      },
      name: 'Payment Method'
    },
    {
      prop: 'status',
      data: (row) => {
        const s = row.status?.toLowerCase();
        const statusType =
          s == 'paid'
            ? 'success'
            : s == 'not approved for payment' || s == 'not approved'
            ? 'failed'
            : 'pending';
        return s && <Badge label={row.status} type={statusType} />;
      },
      name: 'Status'
    },
    {
      prop: 'quickbooks_id',
      name: 'QBD ID',
      data: 'quickbooks_id'
    },
    {
      prop: 'last_updated_in_qbd',
      name: 'Last Updated in QBD',
      data: 'last_updated_in_qbd'
    }
  ];

  return (
    <>
      <DataTable<PaymentSchedule, PaymentSchedulesItems>
        columns={columns}
        subColumns={subColumns}
        items={paymentSchedule}
        rowVisible={rowVisible}
        loading={isLoading}
        isSorting={true}
        totalCount={totalCount}
      />

      {showPaymentScheduleModal && (
        <PaymentScheduleModal
          purchaseOrderId={selectedPurchaseOrderId}
          selectedPoTotalPrice={selectedPoTotalPrice}
          selectedPoOtherBillTotal={selectedPoOtherBillTotal}
          selectedVendorPayment={selectedVendorPayment}
          paymentPoDetails={paymentPoDetails}
          onClose={() => setShowPaymentScheduleModal(false)}
        />
      )}

      {selectedBillId != 0 && (
        <BillUpdateModal
          billId={selectedBillId}
          onClose={() => setSelectedBillId(0)}
          onComplete={() => fetchAllPayments()}
          purchaseOrderId={selectedPurchaseOrderId}
        />
      )}
    </>
  );
});

export default PaymentScheduleTable;
