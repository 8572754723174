import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { deletePurchaseOrderBills, postPaymentSchedule } from 'api/index';
import type {
  PaymentPoDetails,
  PaymentScheduleForm,
  PaymentSchedulesItems,
  POModal,
  PurchaseOrderBills
} from 'api/types';
import AddCirlce from 'assets/images/add_circle.svg';
import CheckCirleSuccess from 'assets/images/check_circle_success.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import CustomDialog from 'components/NewLayout/Dialog';
import DropDown from 'components/NewLayout/Dropdown';
import CustomTextfield from 'components/NewLayout/Textfield';
import { useEffect, useReducer, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Validator from 'simple-react-validator';
import { getValidations } from 'utils/index';

export const StyledLegend = styled('div')(({ theme }) => ({
  fontSize: '16px',
  marginBottom: theme.spacing(1),
  width: 'max-content',
  background: '#fff',
  marginTop: '-29px !important',
  paddingLeft: '7px',
  paddingRight: '5px'
}));

export const paymentMethods: DropdownOptionType[] = [
  { id: 'ACH', value: 'ACH' },
  { id: 'Wire', value: 'Wire' },
  { id: 'Live Check', value: 'Live Check' },
  { id: 'Bill Pay', value: 'Bill Pay' },
  { id: 'Credit Card', value: 'Credit Card' }
];

export default function UpdatePaymentScheduleModal({
  paymentPoDetails,
  paymentSchedules,
  POModalData,
  selectedVendorPayment,
  onClose,
  onComplete
}: {
  paymentPoDetails?: PaymentPoDetails;
  paymentSchedules?: PaymentSchedulesItems[];
  POModalData?: POModal[];
  selectedVendorPayment?: string;
  onClose: () => void;
  onComplete?: () => void;
}) {
  const paymentScheduleObj = {
    index: 0,
    po_in_total: '',
    amount: undefined,
    due_date: '',
    payment_method: selectedVendorPayment,
    disabled: false
  };
  const [modalShow, setShowModal] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<PaymentScheduleForm[]>([]);
  const [deleteBillsList, setDeleteBillsList] = useState<number[]>([]);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const theme = useTheme();

  let total = 0;
  POModalData?.forEach((d) => {
    total += parseFloat(d.price ?? '0') * Number(d?.quantity);
  });

  useEffect(() => {
    if (Array.isArray(paymentSchedules)) {
      const initialPaymentData = paymentSchedules.map((item, index) => {
        const dueDateString = item.due_date;
        let dueDate;

        if (dueDateString) {
          const parsedDate = new Date(dueDateString);
          if (!isNaN(parsedDate.getTime())) {
            dueDate = parsedDate;
          } else {
            dueDate = '';
          }
        } else {
          dueDate = '';
        }

        return {
          index,
          bill_id: item.bill_id,
          disabled: item.status == 'Paid',
          due_date: dueDate ? dueDate.toISOString().split('T')[0] : '',
          po_in_total: (
            ((item.amount != null ? Number(item.amount) : 0) /
              Number(paymentPoDetails?.total_amount?.toString())) *
            100
          ).toFixed(2),
          amount: Number(item.amount),
          payment_method: item.payment_method,
          status: item.status
        };
      });

      setData(initialPaymentData);
    } else {
      setData([]);
    }
  }, [paymentSchedules]);

  const handleChange = (event, index, field) => {
    const { name, value } = event.target;
    if (field == 'amount' && Number(value) < 1) {
      toast.error('Value cannot be equal or less then 1', {
        position: 'bottom-left'
      });
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [field]: ''
        };
        return updatedData;
      });
      return;
    }

    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value
      };
      return updatedData;
    });

    if (name == `data.${index}.amount`) {
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          ['po_in_total']: paymentPoDetails?.total_amount
            ? ((value / Number(paymentPoDetails?.total_amount?.toString())) * 100)
                .toFixed(2)
                .toString()
            : '0'
        };
        return updatedData;
      });
    }
  };

  const submitForm = async () => {
    if (validator.current.allValid()) {
      setLoader(true);

      const amount_sum = data.reduce((acc, item) => acc + Number(item.amount), 0);

      if (amount_sum > Number(paymentPoDetails?.total_amount)) {
        toast.error(
          `Total of Bill(s) can't be greater than Total PO Amount that is : ${paymentPoDetails?.total_amount}`,
          {
            position: 'bottom-left'
          }
        );
        setLoader(false);
      } else if (amount_sum < Number(paymentPoDetails?.total_amount)) {
        toast.error(
          `Total of Bill(s) can't be lesser than Total PO Amount that is : ${paymentPoDetails?.total_amount}`,
          {
            position: 'bottom-left'
          }
        );
        setLoader(false);
      } else {
        const paymentSchedules: PaymentScheduleForm[] = data.map((d) => ({
          bill_id: d?.bill_id || null,
          amount: Number(d.amount),
          due_date: d.due_date,
          payment_method: d.payment_method,
          po_in_total: d.po_in_total,
          status: d.status ?? 'Not Approved for Payment'
        }));

        const purchaseOrderBills: PurchaseOrderBills = {
          paymentSchedules
        };

        postPaymentSchedule(purchaseOrderBills, String(paymentPoDetails?.po_id ?? ''))
          .then(() => {
            if (deleteBillsList.length) {
              return handleDeleteBills();
            }
            return Promise.resolve();
          })
          .then(() => {
            setDeleteBillsList([]);
            if (deleteBillsList.length > 0) {
              toast.success('Bills updated and deleted successfully!', {
                position: 'bottom-left'
              });
            } else {
              toast.success('Bills updated successfully!', {
                position: 'bottom-left'
              });
            }
          })
          .catch((error) => {
            toast.error('Error during bill update or deletion: ' + error, {
              position: 'bottom-left'
            });
          })
          .finally(() => {
            setLoader(false);
            onClose();
          });
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  validator.current.purgeFields();

  const handleAddPaymentSchedule = () => {
    const newIndex = data?.length;
    const newPaymentObj = { ...paymentScheduleObj, index: newIndex };
    setData([...data, newPaymentObj]);
  };

  const handleDeletePaymentSchedule = (indexToDelete: number, billId: number) => {
    setData((prevData) => prevData?.filter((item) => item.index !== indexToDelete));
    if (billId) {
      setDeleteBillsList((prevList) => [...(prevList || []), billId]);
    }
  };

  const handleDeleteBills = () => {
    const formattedBills = {
      billIds: deleteBillsList.map((id) => ({ id }))
    };
    return deletePurchaseOrderBills(formattedBills.billIds);
  };

  return (
    <>
      <CustomDialog
        title="Update Payment Schedule"
        open={modalShow}
        maxWidth="md"
        closeDialog={() => onClose()}
        loading={loader}
        content={
          <>
            <Box className="payment-modal-table">
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Purchase Order Number</TableCell>
                      <TableCell>Total PO Amount</TableCell>
                      <TableCell>Total Paid</TableCell>
                      <TableCell>Total Remaining</TableCell>
                      <TableCell>Total Scheduled</TableCell>
                      <TableCell>Vendor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={'payment-moda-table'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{paymentPoDetails?.po_number || '00000'}</TableCell>
                      <TableCell component="th" scope="row">
                        {'$' + paymentPoDetails?.total_amount.toFixed(2)}
                      </TableCell>
                      <TableCell>{'$' + (paymentPoDetails?.paid_amount.toFixed(2) || 0)}</TableCell>
                      <TableCell>
                        {'$' + paymentPoDetails?.remaining_amount.toFixed(2) || 0}
                      </TableCell>
                      <TableCell>
                        {'$' + (paymentPoDetails?.scheduled_amount.toFixed(2) || 0)}
                      </TableCell>
                      <TableCell>{paymentPoDetails?.vendor_name || 'No Vendor Name'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography component="div" sx={{ marginBottom: '30px !important' }}>
              Create payment schedule by adding the following fields.
            </Typography>
            {data?.map((d, i) => (
              <Box
                key={i}
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: `1px solid #dadada`,
                  borderRadius: 1,
                  p: 2,
                  mb: 3
                }}>
                <fieldset style={{ borderColor: theme.palette.divider }}>
                  <StyledLegend>
                    Payment Schedule
                    <div
                      onClick={() =>
                        !d.disabled &&
                        handleDeletePaymentSchedule(Number(d.index), Number(d?.bill_id))
                      }
                      style={{
                        cursor: d.disabled ? 'not-allowed' : 'pointer',
                        display: 'inline-block',
                        margin: '0 0 6px 12px'
                      }}>
                      <img
                        className="img-fluid link-icon"
                        src={DeleteIcon}
                        alt="Delete Icon"
                        style={{
                          pointerEvents: d.disabled ? 'none' : 'auto'
                        }}
                      />
                    </div>
                  </StyledLegend>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        type="number"
                        min="0"
                        labelText="Amount"
                        name={`data.${i}.amount`}
                        value={d.amount?.toString() ?? ''}
                        onChange={(event) => handleChange(event, i, 'amount')}
                        isRequired
                        maxlength={250}
                        validator={validator}
                        size="medium"
                        disabled={d.disabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        labelText="PO Total in %"
                        name={`data.${i}.po_in_total`}
                        value={d.po_in_total + ' %'}
                        onChange={(event) => handleChange(event, i, 'po_in_total')}
                        isRequired={false}
                        disabled
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        validator={validator}
                        labelText="Due Date"
                        name={`data.${i}.due_date`}
                        value={d.due_date}
                        isRequired={true}
                        onChange={(event) => handleChange(event, i, 'due_date')}
                        type="date"
                        size="medium"
                        disabled={d.disabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropDown
                        label="Payment Method"
                        inputName={`data.${i}.payment_method`}
                        optionText="Select payment method"
                        value={d.payment_method || ''}
                        onChange={(event) => handleChange(event, i, 'payment_method')}
                        options={paymentMethods}
                        validator={validator}
                        disabled={d.disabled}
                        required
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Box>
            ))}
            <Typography
              component="div"
              sx={{ width: '100%', textAlign: 'center', marginTop: '30px !important' }}>
              <CustomButton
                onClick={handleAddPaymentSchedule}
                type="info"
                disabled={Number(paymentPoDetails?.remaining_amount) <= 0}
                label={
                  <>
                    <img src={AddCirlce} style={{ marginRight: '10px' }} /> Add another payment
                  </>
                }
              />
            </Typography>
          </>
        }
        actions={
          <>
            <CustomButton onClick={() => onClose()} variant="outlined" label="Discard" />
            <CustomButton
              disabled={!data?.length || Number(paymentPoDetails?.remaining_amount) <= 0}
              onClick={() => submitForm()}
              label="Update Payment Schedule"
            />
          </>
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#3cbe8e' }}>
            <img src={CheckCirleSuccess} />
            Success!
          </Typography>
        }
        open={successModal}
        maxWidth="xs"
        closeDialog={() => setSuccessModal(false)}
        loading={loader}
        content={<Typography sx={{ mb: 2 }}>Payment schedule created successfully!</Typography>}
        actions={
          <CustomButton
            onClick={() => {
              setSuccessModal(false);
              onClose();
              if (onComplete) onComplete();
            }}
            label="OK"
          />
        }
      />
    </>
  );
}
